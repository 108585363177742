<template>
  <div class="app-item app-content">
    <el-row :gutter="10" class="flex">
      <router-link :to='{name:"Papers"}'>
        <el-col class="open-vip">
          <el-button plain>可用试卷</el-button>
        </el-col>
      </router-link>
    </el-row>
    <div class="table-content">
    <h4>我的测评</h4>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="paper.title" label="考卷名称" align="center"></el-table-column>
      <el-table-column prop="minutes" label="用时(分钟)" align="center"></el-table-column>
      <el-table-column prop="score" label="得分" align="center"></el-table-column>
      <el-table-column prop="" label="" align="center">
        <template slot-scope="props">
          <el-button v-on:click="viewTest(props.row.id)">查看试卷</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Page :pageSize="15" :total="total" @goTo="goTo"></Page>
  </div>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      tableData: [],
      searchParam: {
        page: 1
      }
    }
  },
  methods: {
    viewTest (id) {
      this.$router.push({ name: 'PaperView', params: { id: id } })
    },
    getPapers () {
      this.tableData = []
      this.$store.dispatch('getExams', this.searchParam).then(res => {
        this.tableData = res.data
        this.total = res.meta.total
      })
    },
    goTo (page) {
      this.searchParam.page = page
      this.getPapers()
    }
  },
  mounted () {
    this.getPapers()
  }
}
</script>
<style lang="less" scoped>
</style>
